import axios from "axios";

// export const GROUP_ID = 291422;
// export const GROUP_ID = 481710;
export const GROUP_ID = 211908; // main
export const WIDJET_ID = 301687;

const API_PREFIX = "https://api.yclients.com/api/v1/";

//const BACK_API = "http://localhost:5001/";
const BACK_API = 'https://amo-widgets.herokuapp.com/';


export const PARTHER_TOKEN = "5kda3hw6us9wkds3neyc";

export const instance = axios.create({
  baseURL: API_PREFIX,
  headers: {
    Accept: "application/vnd.yclients.v2+json",
    Authorization: `Bearer ${PARTHER_TOKEN}`,
  },
});

export const instanceBackend = axios.create({
  baseURL: BACK_API,
});

export const instanceVakas = axios.create({
  baseURL: 'https://vakas-tools.ru/widget/sendform/aeaa84f/770/'
});
