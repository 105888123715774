import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  StatLabel,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useAsyncFn } from 'react-use';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { getinfo } from '../../shared/api/auth';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [phone, setPhone] = useState<string>('');

  const [data, onSubmit] = useAsyncFn(async () => {
    try {
      return await getinfo({ phone });
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, [phone]);


  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={<StatLabel style={{ textAlign: 'center', fontSize: 18 }}>Проверка игровых позиций</StatLabel>}
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem' style={{ maxWidth: '300px', marginTop: '24px' }}>
          <InputGroup>
            <InputLeftAddon children='+7' />
            <Input
              maxLength={10}
              type='tel'
              isInvalid={error}
              placeholder='Номер телефона'
              value={phone}
              onChange={event => {
                isNumeric(event.target.value) && setPhone(event?.target.value);
                setError(false);
              }}
            />
          </InputGroup>
          <Stack spacing='1rem' style={{ marginTop: 20 }}>
            {error && (
              <Alert status='error'>
                <AlertIcon />
                Этот номер не попал в список на розыгрыш! <br /> Проверьте правильность введенного Вами номера!
              </Alert>
            )}
          </Stack>
          <Button
            isLoading={data.loading}
            onClick={onSubmit}
            mt='24px'
            w='100%'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Получить данные
          </Button>
        </Stack>
        {data.value?.data.length > 0 && (
          <TableContainer style={{ marginTop: '16px', width: '300px', alignSelf: 'center' }}>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th style={{ padding: '8px', textAlign: 'center' }}>Билет</Th>
                  <Th style={{ padding: '8px', textAlign: 'center' }}>Номер</Th>
                  <Th style={{ padding: '8px', textAlign: 'center' }}>Сумма</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* {{data.value?.data.map((x: any) => (
                  <Tr>
                    <Td style={{ padding: '8px', textAlign: 'center' }}>{x.number}</Td>
                    <Td style={{ padding: '8px', textAlign: 'center' }}>{x.phone}</Td>
                    <Td style={{ padding: '8px', textAlign: 'center' }}>{x.pay}</Td>
                  </Tr>
                ))}} */}
                {[].map((x: any) => (
                  <Tr>
                    <Td style={{ padding: '8px', textAlign: 'center' }}>{x.number}</Td>
                    <Td style={{ padding: '8px', textAlign: 'center' }}>{x.phone}</Td>
                    <Td style={{ padding: '8px', textAlign: 'center' }}>{x.pay}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          ❗️Каждая потраченная 1000 рублей = 1 слот в списке участников. <br />
          Любая потраченная сумма превращается в слот кратно 1000 рублей. <br />
          Пример №1: Купили услуг на 2500 = 2 слота. <br />
          Пример №2: Купили услуг на 3900 = 3 слота. <br />
          ❗️Округление происходит в меньшую сторону до ровного значения.
        </div>
      </Box>
    </AppTemplate>
  );
});
